import React, { Fragment, useState, useEffect }  from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import Image1 from "../../Assets/Images/explore-img-3.png"

import './Series1.css'
function Series1(props) {
    const [seriesdata, setSeriesdata] = useState([]);
  
    useEffect(() => {
      if (props.data && props.data.length > 0) {
        let foundIndex = props.data.findIndex(item => item.slug == 'mint')
        if (foundIndex > -1) {
            console.log(props.data[foundIndex]?.acf?.series);
            setSeriesdata(props.data[foundIndex]?.acf?.series)
        }
      }
    }, [props]);

    let navigate = useNavigate();
    const details = (seriesItem) => {
        if(seriesItem.enable == true){
            navigate("/passes", {state:{seriesItem:seriesItem}});
        }else if(seriesItem.name == "Series 2 : Gods and Demons"){
            navigate("/series2", {state:{seriesItem:seriesItem}});
        }else{
            navigate("/comingsoon", {state:{seriesItem:seriesItem}});
        }
    };
    const details_lore = (seriesItem) => {
        if(seriesItem.enable == true){
            navigate("/lore", {state:{seriesItem:seriesItem}});
        }else if(seriesItem.name == "Series 2 : Gods and Demons"){
            navigate("/series2", {state:{seriesItem:seriesItem}});
        }else{
            navigate("/comingsoon", {state:{seriesItem:seriesItem}});
        }
    };
    return (
        <div className='team-main mint items-list'>
            <Container>
                <div className="product-detail">
                {  
                seriesdata.length > 0 && seriesdata.map((seriesItem) =>
                    <div className="item">
                        <div className="image">
                            <img src={seriesItem.image.url} alt="" className="img-fluid" />
                        </div>
                        <div className="description">
                            <h3>{seriesItem.name}</h3>
                            <p>Mint Size: {seriesItem.size}</p>
                            <p>Mint Price: {seriesItem.price}</p>
                            <ul>
                            <div dangerouslySetInnerHTML={{__html: seriesItem.description}}></div>
                            </ul>
                            <div className="btn-actions">
                                <button className="round-radius-btn yellow" onClick={() => details(seriesItem)}>Mint {seriesItem.name}</button>
                                <button className="round-radius-btn btn" onClick={() => details_lore(seriesItem)}>{seriesItem.name} Lore </button>
                            </div>
                            {seriesItem.name == "Series 2 : Gods and Demons" ?
                            <div className="btn-actions series2_btn">
                                <button className="round-radius-btn yellow"> Series : 2 game </button>
                                <button className="round-radius-btn btn"> Series 2 : featured artists </button>
                            </div>
                            : '' }
                        </div>
                    </div>
                       )
                    }
                </div>

            </Container>
        </div>
    )
}

export default Series1