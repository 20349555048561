import React, {useEffect, useState} from 'react'
import {Container,  Row, Col, Navbar, Nav, Button, Offcanvas} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import bannerContentMainBgLayer from '../../Assets/Images/banner-content-bg-layer.png'
import bannerContentMainBgPulse from '../../Assets/Images/banner-pulse-img.png'
import Mint from '../../Components/Mint/Mint'
import './Banner.css'

function Banner(props) {

const [url, setUrl] = useState('');
const [indtext, setIndtext] = useState('');
const [btntext, setBtntext] = useState('');
const [btnurl, setBtnurl] = useState('');
  
  useEffect(() => {
    if(props.data && props.data.length > 0 ){
        let foundIndex = props.data.findIndex(item => item.slug == 'home')
        if(foundIndex > -1) {
        setUrl(props.data[foundIndex]?.acf?.header_girl_image?.url)
        setIndtext(props.data[foundIndex]?.acf?.header_text)
        setBtntext(props.data[foundIndex]?.acf?.header_button_text)
        setBtnurl(props.data[foundIndex]?.acf?.header_button_url.url)
        }
    }
  }, [props]);
  return (
    <div className='main-banner position-relative d-flex flex-column justify-content-center align-items-center'>
        <div className='main-banner-content'>
            <Row>
                <Col lg={12}>
                    <div className='banner-main-img'>
                        <img src={url} alt="girl-image" className='img-fluid'/>
                    </div>
                    <div className='banner-main-img-layer'>
                        <img src={bannerContentMainBgLayer} alt="layer" className='img-fluid'/>
                    </div>
                    <div className='banner-main-img-pulse'>
                        <img src={bannerContentMainBgPulse } alt="girl-image" className='img-fluid'/>
                    </div>
                    <div className='text-end banner-text-content position-relative'>
                        <h1>{indtext}</h1>
                        <Link to={btnurl} class="home_mint_link"><Button type="button" className='main-btn'>{btntext}</Button></Link> 
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Banner