import React, {useEffect, useState} from 'react'
import { a } from 'react-router-dom'
import Lottie from 'react-lottie-player'
import lottieTwitter from '../../Assets/Lottie/lottie-twitter.json'
import lottieDiscord from '../../Assets/Lottie/lottie-discord.json'

import './TeamMain.css'
function TeamMain(props) {

    const [teammaindata, setTeammaindata] = useState([]);

    useEffect(() => {
        if(props.data && props.data.length > 0 ){
            let foundIndex = props.data.findIndex(item => item.slug == 'home')
            if(foundIndex > -1) {
            setTeammaindata(props.data[foundIndex]?.acf?.team_slider);
            }

        }
      }, [props]);



  return (
    <div className='team-main'>
        <div className='custom-container'>
        {  
            teammaindata.length > 0 && teammaindata.map((teammain) =>
            <div className='team-main-single team-main-single-odd d-md-flex align-items-center mb-4'>
                <div className='team-main-img d-inline-block'>
                    <img src={teammain.image.url} alt="team" className='img-fluid'/>
                </div>
                <div className='team-img-intro'>
                    <strong className='d-block'>{teammain.name}</strong>
                    <div dangerouslySetInnerHTML={{__html: teammain.content}}></div>
                    <div  className='d-flex align-items-center team-block-icons-row'>
                        <a target="_blank" href={teammain.twitter} className='team-single-box-icons-discord  me-2 p-2 bd-dark'>
                            <Lottie loop animationData={lottieTwitter} play className='twitter-lottie'/>
                        </a>
                        <a target="_blank" href={teammain.dicote} className='team-single-box-icons-discord'>
                            <Lottie loop animationData={lottieDiscord} play className='dicord-lottie'/>
                        </a>
                    </div>
                </div>
            </div>
             )
            }
        </div>
    </div>
  )
}

export default TeamMain