import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Row, Col, Button } from 'react-bootstrap'
import LoreMinting from '../LoreMinting/LoreMinting'
import './LoreContent.css'

function LoreContent() {
    const [data, setData] = useState('');
    const [series_section_main_heading, setSeries_section_main_heading] = useState('');
    const [series_section_sub_heading, setSeries_section_sub_heading] = useState('');
    const [series_section_content, setSeries_section_content] = useState('');
    const [endup_heading, setEndup_heading] = useState('');
    const [endup_content_1, setEndup_content_1] = useState('');
    const [endup_content_2, setEndup_content_2] = useState('');
    const [endup_content_3, setEndup_content_3] = useState('');
    const [robots_section_heading, setRobots_section_heading] = useState('');
    const [robots_section_content, setRobots_section_content] = useState('');
    const [batteries_section_heading, setBatteries_section_heading] = useState('');
    const [batteries_section_content, setBatteries_section_content] = useState('');
    const [double_u_section_heading, setDouble_u_section_heading] = useState('');
    const [double_u_section_content, setDouble_u_section_content] = useState('');
    const [double_u_section_button_text, setDouble_u_section_button_text] = useState('');
    const [double_u_section_button_link, setDouble_u_section_button_link] = useState('');

  
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
            .then(res => {
                let foundIndex = res.data.findIndex(item => item.slug == 'lore')
                if(foundIndex > -1) {
                setData(res.data)
                setSeries_section_main_heading(res.data[foundIndex]?.acf?.series_section_main_heading)
                setSeries_section_sub_heading(res.data[foundIndex]?.acf?.series_section_sub_heading)
                setSeries_section_content(res.data[foundIndex]?.acf?.series_section_content)
                setEndup_heading(res.data[foundIndex]?.acf?.endup_heading)
                setEndup_content_1(res.data[foundIndex]?.acf?.endup_content_1)
                setEndup_content_2(res.data[foundIndex]?.acf?.endup_content_2)
                setEndup_content_3(res.data[foundIndex]?.acf?.endup_content_3)
                setRobots_section_heading(res.data[foundIndex]?.acf?.robots_section_heading)
                setRobots_section_content(res.data[foundIndex]?.acf?.robots_section_content)
                setBatteries_section_heading(res.data[foundIndex]?.acf?.batteries_section_heading)
                setBatteries_section_content(res.data[foundIndex]?.acf?.batteries_section_content)
                setDouble_u_section_heading(res.data[foundIndex]?.acf?.double_u_section_heading)
                setDouble_u_section_content(res.data[foundIndex]?.acf?.double_u_section_content)
                setDouble_u_section_button_text(res.data[foundIndex]?.acf?.double_u_section_button_text)
                setDouble_u_section_button_link(res.data[foundIndex]?.acf?.double_u_section_button_link.url)
                }
            })
            .catch(err => console.log(err))
    }, []);
    return (
        <>
            <div className=' lore-content-odd'>
                <div className='custom-container'>
                    <LoreMinting data={data} />
                    <div className='lore-content'>
                        <h2>{series_section_main_heading}</h2>
                        <section>
                            <h3>{series_section_sub_heading}</h3>
                            <div dangerouslySetInnerHTML={{__html: series_section_content}}></div>
                        </section>
                        <section className='lore-endup'>
                            <h4>{endup_heading}</h4>
                            <Row className='align-items-center'>
                                <Col lg={6}>
                                    <div className='lore-endup-content'>
                                        <div dangerouslySetInnerHTML={{__html: endup_content_1}}></div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='lore-endup-content'>
                                    <div dangerouslySetInnerHTML={{__html: endup_content_2}}></div>
                                    </div>
                                </Col>
                            </Row>
                        </section>
                        <section>
                        <div dangerouslySetInnerHTML={{__html: endup_content_3}}></div>
                            <strong>
                                <p className='mb-1'>They all have a punk ethos: the status quo cannot continue, and revolution is coming.</p>
                                <p>But ya gotta eat, so earn some cash while you fight the man.</p>
                            </strong>
                        </section>
                    </div>
                </div>
            </div>
            <div className=' lore-content-even'>
                <div className='custom-container'>
                    <div className='lore-content'>
                        <section>
                            <h3>{robots_section_heading}</h3>
                            <div dangerouslySetInnerHTML={{__html: robots_section_content}}></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className=' lore-content-odd'>
                <div className='custom-container'>
                    <div className='lore-content'>
                        <section>
                            <h3>{batteries_section_heading}</h3>
                            <div dangerouslySetInnerHTML={{__html: batteries_section_content}}></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className=' lore-content-even'>
                <div className='custom-container'>
                    <div className='lore-content'>
                        <section>
                            <h3>{double_u_section_heading}</h3>
                            <div dangerouslySetInnerHTML={{__html: double_u_section_content}}></div>
                        </section>
                    </div>
                    <div className='text-center'>
                        <Link to={double_u_section_button_link}>
                        <Button type="button" className='round-radius-btn'>{double_u_section_button_text}</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoreContent