import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Button} from 'react-bootstrap'

import './LoreMinting.css'
function LoreMinting(props) {
 
  const [lore_minting_heading, setLore_minting_heading] = useState('');
  const [lore_minting_content, setLore_minting_content] = useState('');
  const [lore_minting_button_text, setLore_minting_button_text] = useState('');
  const [lore_minting_button_link, setLore_minting_button_link] = useState('');

  useEffect(() => {
      if(props.data && props.data.length > 0 ){
        let foundIndex = props.data.findIndex(item => item.slug == 'lore')
        if(foundIndex > -1) {
        setLore_minting_heading(props.data[foundIndex]?.acf?.lore_minting_heading)
        setLore_minting_content(props.data[foundIndex]?.acf?.lore_minting_content)
        setLore_minting_button_text(props.data[foundIndex]?.acf?.lore_minting_button_text)
        setLore_minting_button_link(props.data[foundIndex]?.acf?.lore_minting_button_link)
        }
      }
    }, [props]);
  return (
    <div className='custom-container'>
        <div className='lore-minting'>
            <h2>{lore_minting_heading}</h2>
            <div dangerouslySetInnerHTML={{__html: lore_minting_content}}></div>
            <Button type={lore_minting_button_link} className='main-btn dicover-mint-btn'>{lore_minting_button_text}</Button>
        </div>
    </div>
  )
}

export default LoreMinting