import React, { useEffect, useState } from 'react'
import {useLocation } from 'react-router-dom'
import { Container, Button,ProgressBar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import MerkleTree from 'merkletreejs';
import { keccak256 } from 'ethers/lib/utils';
import '../Passes/Passes.css'
import Web3 from 'web3'
import Series2abi from '../../Abi/series2abi.json';
import MerkleMint from '../../Abi/merklemint.json';
var Contract = require('web3-eth-contract');






var web3 = new Web3(Web3.givenProvider || 'https://rinkeby.infura.io/v3/8f28c0181af64185b5e179576eb4301d');

function Premint() {
  const [mint_your_passes, setMint_your_passes] = useState('');
  const [series_name, setSeries_name] = useState('');
  const [data, setData] = useState({ address: null, balance: null });
  const location = useLocation();
  const [burnPass, setBurnPass] = useState(false);
  const [mintPass, setMintPass] = useState(false);
  const [_root, setRoot] = useState()
  const [_proof, setProof] = useState()
  const [limit, setLimit] = useState()
  const [progressbar, setProgressbar] = useState()
  const [eligibility, setEligibility] = useState('');
  const percentage = (progressbar/10000)*100;
  const now = percentage.toFixed(2);
  const progressInstance = <ProgressBar now={now} label={`${now}%`} />;
  useEffect(() => {
    window.scrollTo(0, 0);
       btnhandler();
       merkle_tree();
       handle_premint_time();
       axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
            .then(res => {
                if(res.data && res.data.length > 0 ){
                  let foundIndex = res.data.findIndex(item => item.slug == 'passes')
                  if(foundIndex > -1) {
                    setMint_your_passes(res.data[foundIndex]?.acf?.mint_your_nfts)
                  }
              }
              if(res.data && res.data.length > 0 ){
                let foundIndex = res.data.findIndex(item => item.slug == 'mint')
                if(foundIndex > -1) {
                    setSeries_name(res.data[foundIndex]?.acf?.series[1]?.name)
                }
            }
            })
            .catch(err => console.log(err))
  }, []);

  const handleroot = async () => {
    try {
      let contract = new web3.eth.Contract(MerkleMint, process.env.REACT_APP_MERKLE_MINT_CONTRACT_ADDRESS);
      const savemint = await contract.methods.setRoot(_root).send({ from: data.address });
    //   const balance = await contract.methods.balanceOf(data.address).call();
    } catch (err) {
      console.log(err)
    }
  }
  const merkle_tree = async () => {
    const buf2hex = x => '0x'+x.toString('hex')
var leaves  = [
  "0x7ACf46627094FA89339DB5b2EB862F0E8Ea4D9fc",
  "0xf0EBDd31b3C62683f6463E67aCD14D9cE4FA4d43",
  "0x25eb1e647261C7DbE696536572De61b1a302a83C",
  "0xf35B008dC2737E4394BF0b671E54A74d2fFDD9a3",
  "0xFFE4e3C809418aEFB00922ffCa6b46d9d8f41B6c",
  "0x04EBd55626d3Bc2bB29D895c6d6f13Bf44319cA3",
  "0x802B2a6C8eF900362C2AD22855F4Df0843CC3d4c",
  "0x2E868A00536e10445a1511e55D5D10aa9E809C3f",
  "0xA44c068BA29DE9720ca598819017f2E3Cfd37088"
].map(v => keccak256(v))

const tree = new MerkleTree(leaves, keccak256, { sort: true })
const root = tree.getRoot()
const hexroot = buf2hex(root)
console.log(hexroot)
const leaf = keccak256('0xA44c068BA29DE9720ca598819017f2E3Cfd37088')
const hexleaf = buf2hex(leaf)
console.log(hexleaf)
const proof = tree.getProof(leaf)
const hexproof = tree.getProof(leaf).map(x => buf2hex(x.data))
console.log(hexproof)



setProof(hexproof)
}
  const setMintPassHandler = () => {
    handle_premint_time();
    setBurnPass(false);
    setMintPass(true);
  }

  const backButtonHandler = () => {
    setBurnPass(false);
    setMintPass(false);
  }

  const [counter, setCounter] = useState(1);

  //increase counter
  const increase = () => {
    handle_premint_time();
    console.log("limit", limit);
    if(counter == limit){
      setCounter(count => count);
    }else{
      setCounter(count => count + 1);
    }
  };

  //decrease counter
  const decrease = () => {
    handle_premint_time();
    if(counter == 1){
        setCounter(count => 1);
    }else{
        setCounter(count => count - 1);
    }
  };
  
  const minimum = () => {
    handle_premint_time();
    setCounter(count => 1);
  };
   
  const maximum = () => {
    handle_premint_time();
    setCounter(count => limit);
  };
   
  const [isMinting, setIsMinting] = useState(false);

  const btnhandler = async () => {

    if (data.address) {
      setData({ address: null, balance: null })
    } else {
      // Asking if metamask is already present or not
      if (window.ethereum) {

        // res[0] for fetching a first wallet
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then(async (res) => {
            const chainId = await web3.eth.getChainId();
            const balance = await web3.eth.getBalance(res[0])


            if (window.ethereum.networkVersion !== process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) {
              try {
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) }]
                });
              } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                  await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                      {
                        chainName: 'Ethereum Mainnet',
                        chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID),
                        nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
                        rpcUrls: ['https://mainnet.infura.io/v3/']
                      }
                    ]
                  });
                }
              }
            }
            setData({ address: res[0], balance: balance });
            let contract = new web3.eth.Contract(MerkleMint, process.env.REACT_APP_MERKLE_MINT_CONTRACT_ADDRESS);
          });
      } else {
        alert("install metamask extension!!");
      }
    }
  };

  async function getAccount() {
    const accounts = await window.ethereum.enable();
    const account = accounts[0];
    const balance = await web3.eth.getBalance(account);

    if (window.ethereum.networkVersion !== process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) }]
        });
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Ethereum Mainnet',
                chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID),
                nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
                rpcUrls: ['https://mainnet.infura.io/v3/']
              }
            ]
          });
        }
      }
    }

    setData({ address: account, balance: balance });
  }

  window.ethereum.on('accountsChanged', function (accounts) {
    getAccount();
  })


  const handlemint = async () => {
    try {
      setIsMinting(true);
      console.log("_proof", _proof);
      let contract =  new web3.eth.Contract(Series2abi, process.env.REACT_APP_series2_CONTRACT_ADDRESS);
      const mintLeftover = await contract.methods.mintSale(counter,_proof).send({ from: window.ethereum.selectedAddress});
      if(mintLeftover){
        setIsMinting(false);
      }else{
        console.log("fail minting");
      }
    } catch (err) {
      setIsMinting(false)
      console.log(err)
    }
  }
  const handle_premint_time = async () => {
    try {
      let contract = new web3.eth.Contract(Series2abi, process.env.REACT_APP_series2_CONTRACT_ADDRESS);
      const timestamp = await contract.methods.checkLimit(window.ethereum.selectedAddress, _proof).call();
      if(timestamp == 0){
        setCounter(0);
        setEligibility("You do not have series 1 NFT and not White listed.");
      }
      const progressbar1 = await contract.methods.mintCount().call();
      console.log("progressbar1" , progressbar1);
      setProgressbar(progressbar1);
      setLimit(timestamp);
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <section className='mint-detail-wrapper burning-box'>
      <Container>
        {!burnPass && !mintPass ?
          <div className='detail-box text-center pt-0'>
            <button className='text-uppercase btn-pass' onClick={setMintPassHandler}>mint your NFTs</button>
            <div className='mint_your_passes'>
              <div dangerouslySetInnerHTML={{__html: mint_your_passes}}></div>
            </div>
          </div> : null}
        {
          burnPass &&
          <div className='detail-box text-center position-relative'>
          </div>
        }
        {
          mintPass &&
          <div className='detail-box text-center position-relative mt-5 pt-5'>
            <p className='text-uppercase'>{series_name}</p>
            <h2 className='text-uppercase mb-3'>minitng</h2>
            <span className='minting-subtitle'>Enter the legend and generate one of the first ethnos.</span>
            <div className='btn-wrapper mt-5 d-flex flex-column align-items-center'>
              <Button className='btn mb-2 btn-1' onClick={() => btnhandler()} variant='primary'>
                {data.address ? data.address.replace(data.address.substring(4, 38), '.....') : 'CONNECT'}
              </Button>
              <div className="counter mb-2">
                  <div className="btn__container">
                    <button className="control__btn" onClick={minimum}><FontAwesomeIcon icon={faAnglesLeft} /></button>
                    <button className="control__btn" onClick={decrease}><FontAwesomeIcon icon={faChevronLeft} /></button>
                    <span className="counter__output">{counter}</span>
                    <button className="control__btn" onClick={increase}><FontAwesomeIcon icon={faChevronRight} /></button>
                    <button className="control__btn" onClick={maximum}><FontAwesomeIcon icon={faAnglesRight} /></button>
                  </div>
              </div>
              
              {isMinting ?<button type='button' disabled className='btn btn-4' >minting ...</button> :<button type='button' className='btn btn-4' onClick={() => handlemint()}>mint</button>}
              <div><span className='mint-bottom-text lessbalace'>{eligibility}</span></div>
              <div className='progress-wrapper'>
                {progressInstance}
              </div>
            </div>
          </div>

        }
        {burnPass || mintPass ?
          <div className='back-btn text-center'>
            <button type='button' onClick={backButtonHandler}><FontAwesomeIcon icon={faArrowLeft} /></button>
          </div> : null}
      </Container >
    </section >
  )
}

export default Premint