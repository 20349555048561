import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {Container, Row, Col, Navbar, Nav, Button, Offcanvas, a} from 'react-bootstrap'
// import discoverImgBg from '../../Assets/Images/discover-img-bg.png'
import './Discover.css'
function Discover(props) {

const [discover_heading, setDiscover_heading] = useState('');
const [discover_main_heading, setDiscover_main_heading] = useState('');
const [discover_sub_heading, setDiscover_sub_heading] = useState('');
const [discover_content2, setDiscover_content2] = useState('');
const [discover_left_text, setDiscover_left_text] = useState('');
const [discover_mining_number, setDiscover_mining_number] = useState('');
const [discover_mining_button_text, setDiscover_mining_button_text] = useState('');
const [discover_mining_button_url, setDiscover_mining_button_url] = useState('');
  
  useEffect(() => {
    if(props.data && props.data.length > 0 ){
        let foundIndex = props.data.findIndex(item => item.slug == 'home')
        if(foundIndex > -1) {
        setDiscover_heading(props.data[foundIndex]?.acf?.discover_heading)
        setDiscover_main_heading(props.data[foundIndex]?.acf?.discover_main_heading)
        setDiscover_sub_heading(props.data[foundIndex]?.acf?.discover_sub_heading)
        setDiscover_content2(props.data[foundIndex]?.acf?.discover_content2)
        setDiscover_left_text(props.data[foundIndex]?.acf?.discover_left_text)
        setDiscover_mining_number(props.data[foundIndex]?.acf?.discover_mining_number)
        setDiscover_mining_button_text(props.data[foundIndex]?.acf?.discover_mining_button_text)
        setDiscover_mining_button_url(props.data[foundIndex]?.acf?.discover_mining_button_link?.url)
        }
    }
  }, [props]);

  return (
    <div className='discover-main position-relative'>
        <div className='custom-container'>
            <Row className='align-items-center'>
                <Col lg={4}>
                    <div className='start-minting-box d-flex flex-column justify-content-center align-items-center'>
                        <p>{discover_left_text}</p>
                        <span>{discover_mining_number}</span>
                        <Button type="button" className='main-btn dicover-mint-btn'>{discover_mining_button_text}</Button>
                    </div>
                </Col>
                <Col lg={8}>
                    <div className='discover-content'>
                        <h3 className='mb-2'>{discover_heading}</h3>
                        <h2 className='mb-3'>{discover_main_heading}</h2>
                        <h3>{discover_sub_heading}</h3>
                        <div className='mb-2' dangerouslySetInnerHTML={{__html: discover_content2}}></div>
                        <p className='mb-5'></p>
                        <Link to={discover_mining_button_url} class="home_mint_link"><Button type="button" className='round-radius-btn'>{discover_mining_button_text}</Button></Link>
                    </div>
                    {/* <div className='discover-img-bg'>
                        <img src={discoverImgBg} alt="discover" className='img-fluid'/>
                    </div> */}
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Discover