import React from 'react'
import ComingSoon from '../ComingSoon/ComingSoon'

function Vault() {
  return (
    <>
        <ComingSoon/>
    </>
  )
}

export default Vault