import React from 'react'
import ComingSoon from '../ComingSoon/ComingSoon'

function Traits() {
  return (
    <>
        <ComingSoon/>
    </>
  )
}

export default Traits