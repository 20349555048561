import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Container, Row, Col, Navbar, Nav, Button, Offcanvas } from 'react-bootstrap'
import logo from '../../Assets/Images/ethnology-logo.svg'
import { Link } from 'react-router-dom';
import './Header.css'

function Header() {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(!show);

	const [menuData, setMenuData] = useState([]);

	useEffect(() => {

		axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/menu')
			.then(res => {
				if (res.data[0]?.acf?.menu_items && res.data[0]?.acf?.menu_items.length > 0) {
					setMenuData(res.data[0]?.acf?.menu_items)
				}
			})
			.catch(err => console.log(err))
	}, []);


	return (
		<header id='header' className={`${show ? 'hide-white' : ''} pt-3`}>
			<div className='custom-container'>
				<Row>
					<Col className='col-12'>
						<Navbar collapseOnSelect expand="" className='header-navbar'>
							<strong className='logo'>
								<Link to='/' className='d-inline-block'>
									<img src={logo} alt='logo' className='img-fluid' />
								</Link>
							</strong>
							<Navbar.Toggle onClick={handleShow} data-bs-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation" data-bs-target="#responsive-navbar-nav" aria-controls="responsive-navbar-nav" />
							<Navbar.Collapse show={show} onHide={handleClose} id="responsive-navbar-nav" >
								<Nav className="my-2 my-lg-0 text-end header-nav">
									{
										menuData.length > 0 && menuData.map((item, i) =>
											<Nav.Link className="nav-link" eventKey={i} as={Link} to={item.link}>{item.text}</Nav.Link>
										)
									}
								</Nav>
							</Navbar.Collapse>
						</Navbar>
					</Col>
				</Row>
			</div>
		</header >
	)
}

export default Header