import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Container, Row, Col, Button,a} from 'react-bootstrap'
// import logo from '../../Assets/Images/ethnology-logo.svg'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord} from '@fortawesome/free-brands-svg-icons';
import './Footer.css'

function Footer() {

    const [logo, setLogo] = useState('');
    const [copyright_text, setCopyright_text] = useState('');
    const [dicote_text, setDicote_text] = useState('');
    const [twitter_text, setTwitter_text] = useState('');
    const [community_text, setCommunity_text] = useState('');
    const [twitter_link, setTwitter_link] = useState('');
    const [dicote_url, setDicote_url] = useState('');
        
    useEffect(() => {
  
      axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/footer')
      .then(res => {
          setLogo(res.data[0]?.acf?.logo?.url)
          setCopyright_text(res.data[0]?.acf?.copyright_text)
          setDicote_text(res.data[0]?.acf?.dicote_text)
          setTwitter_text(res.data[0]?.acf?.twitter_text)
          setCommunity_text(res.data[0]?.acf?.community_text)
          setTwitter_link(res.data[0]?.acf?.twitter_link?.url)
          setDicote_url(res.data[0]?.acf?.dicote_url?.url)
      })
      .catch(err => console.log(err))
    }, []);
  return (
    <div className='footer'>
        <div className='custom-container'>
            <Row className='align-items-center'>
                <Col sm={6}>
                    <div className='footer-logo'>
                        <strong className='logo d-inline-block'>
                            <Link to='/' className='d-inline-block'>
                                <img src={logo} alt='logo' className='img-fluid'/>
                            </Link>
						</strong>
                        <p className="mb-0">{copyright_text}</p>
                    </div>
                </Col>
                <Col sm={6}>
                    <div className='footer-buttons text-end'>
                        <div className='d-flex footer-buttons-wrapper justify-content-end mb-3'>
                        <a target="_blank" href={twitter_link} >
                        <Button type="button" className='twitter-btn '>
                            <FontAwesomeIcon icon={faTwitter} className="me-2"/>
                            <span>{twitter_text}</span>
                        </Button>
                        </a>
                        <a target="_blank" href={dicote_url} >
                        <Button type="button" className='discord-btn ms-3'>
                            <FontAwesomeIcon icon={faDiscord} className="me-2"/>
                            <span>{dicote_text}</span>
                        </Button>
                        </a>
                        </div>
                        <p className="mb-0">{community_text}</p>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Footer