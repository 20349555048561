import React, { useState, useEffect } from 'react';
import axios from 'axios';
import teamBannerBg from '../../Assets/Images/team-banner-bg.png'
import Header from '../Header/Header'
import Footer from "../Footer/Footer"
import TeamMain from '../TeamMain/TeamMain'
import JoinUS from '../JoinUS/JoinUS'
import InnerBanner from '../InnerBanner/InnerBanner'
function TeamPage() {
  const [data, setData] = useState('');
  const [banner_main_heading, setBanner_main_heading] = useState('');
	const [banner_sub_heading, setBanner_sub_heading] = useState('');
  const [banner_image, setBanner_image] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
      axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
          .then(res => {
            let foundIndex = res.data.findIndex(item => item.slug == 'team')
            if(foundIndex > -1) {
              setData(res.data)
              setBanner_main_heading(res.data[foundIndex]?.acf?.banner_main_heading)
              setBanner_sub_heading(res.data[foundIndex]?.acf?.banner_sub_heading)
              setBanner_image(res.data[foundIndex]?.acf?.banner_image?.url)
            }
          })
          .catch(err => console.log(err))
  }, []);
  return (
    <>
       
		<InnerBanner heading={banner_main_heading} description={banner_sub_heading} innerbanner={banner_image}/>
        <TeamMain data={data} />
		
    </>
  )
}

export default TeamPage