import React, { Fragment, useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import Web3 from 'web3'
import { Container, Col, Row, Card, Button } from 'react-bootstrap'
import ExploreImage1 from "../../Assets/Images/explore-img-1.png"
import ExploreImage2 from "../../Assets/Images/sliderimg2.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Mint.css'
import Contractabi from '../../contractabi.json';
var Contract = require('web3-eth-contract');


var web3 = new Web3(Web3.givenProvider || 'https://rinkeby.infura.io/v3/8f28c0181af64185b5e179576eb4301d');

function Mint(props) {
    const [seriesdata, setSeriesdata] = useState([]);
  
    useEffect(() => {
      if (props.data && props.data.length > 0) {
        let foundIndex = props.data.findIndex(item => item.slug == 'mint')
        if (foundIndex > -1) {
            setSeriesdata(props.data[foundIndex]?.acf?.series)
        }
      }
    }, [props]);
    let navigate = useNavigate();
    const details = (seriesItem) => {
        if(seriesItem.enable == true){
            navigate("/passes", {state:{seriesItem:seriesItem}});
        }else if(seriesItem.name == "Series 2 : Gods and Demons"){
            navigate("/series2", {state:{seriesItem:seriesItem}});
        }else{
            navigate("/comingsoon", {state:{seriesItem:seriesItem}});
        }
        
    };
  
    var settings = {
      dots: false,
      loop: true,
      margin: 20,
      nav: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 3,
            }
         },
         {
            breakpoint: 575,
            settings: {
               slidesToShow: 1,
               adaptiveHeight: true,
            }
         }
      ]
      };
    return (
        <Fragment>
            <div className='mint-slider-sec'>
                <Slider {...settings}  className="mint-slider">
                {  
                seriesdata.length > 0 && seriesdata.map((seriesItem) =>
                    <div className='mint-slide-wrap' onClick={() => details(seriesItem)}>
                        <div className="img-wrapper">
                            <img src={seriesItem.image.url} className="img-fluid"/>
                            <div className="description">
                                <h4>{seriesItem.name}</h4>
                                <p className='mb-0'>Mint Size: {seriesItem.size}</p>
                                <p>Mint Price: {seriesItem.price}</p>
                                <ul>
                                <div dangerouslySetInnerHTML={{__html: seriesItem.description}}></div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    )
                }
                {  
                seriesdata.length == 3 && seriesdata.map((seriesItem) =>
                    <div className='mint-slide-wrap' onClick={() => details(seriesItem)}>
                        <div className="img-wrapper">
                            <img src={seriesItem.image.url} className="img-fluid"/>
                            <div className="description">
                                <h4>{seriesItem.name}</h4>
                                <p className='mb-0'>Mint Size: {seriesItem.size}</p>
                                <p>Mint Price: {seriesItem.price}</p>
                                <ul>
                                <div dangerouslySetInnerHTML={{__html: seriesItem.description}}></div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    )
                }
                </Slider>
            </div>
        </Fragment>
    )
}

export default Mint