import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Nav, Button, Tab} from 'react-bootstrap'

import './Explore.css'

function Explore(props) {


    const [explore_tab1, setExplore_tab1] = useState('');
    const [explore_tab2, setExplore_tab2] = useState('');
    const [explore_tab3, setExplore_tab3] = useState('');
    const [section_content_tab_1, setSection_content_tab_1] = useState('');
    const [section_content_tab_2, setSection_content_tab_2] = useState('');
    const [section_content_tab_3, setSection_content_tab_3] = useState('');
    const [explore_button_text_tab1, setExplore_button_text_tab1] = useState('');
    const [explore_button_text_tab2, setExplore_button_text_tab2] = useState('');
    const [explore_button_text_tab3, setExplore_button_text_tab3] = useState('');
    const [explore_image_tab1, setExplore_image_tab1] = useState('');
    const [explore_image_tab2, setExplore_image_tab2] = useState('');
    const [explore_image_tab3, setExplore_image_tab3] = useState('');
    const [explore_button_url_tab2, setExplore_button_url_tab2] = useState('');
    const [explore_button_url_tab3, setExplore_button_url_tab3] = useState('');
    
      
      useEffect(() => {
        if(props.data && props.data.length > 0) {
            let foundIndex = props.data.findIndex(item => item.slug == 'home')
            if(foundIndex > -1) {
            setExplore_tab1(props.data[foundIndex]?.acf?.explore_tab1)
            setExplore_tab2(props.data[foundIndex]?.acf?.explore_tab2)
            setExplore_tab3(props.data[foundIndex]?.acf?.explore_tab3)
            setSection_content_tab_1(props.data[foundIndex]?.acf?.section_content_tab_1)
            setSection_content_tab_2(props.data[foundIndex]?.acf?.section_content_tab_2)
            setSection_content_tab_3(props.data[foundIndex]?.acf?.section_content_tab_3)
            setExplore_button_text_tab1(props.data[foundIndex]?.acf?.explore_button_text_tab1)
            setExplore_button_text_tab2(props.data[foundIndex]?.acf?.explore_button_text_tab2)
            setExplore_button_text_tab3(props.data[foundIndex]?.acf?.explore_button_text_tab3)
            setExplore_image_tab1(props.data[foundIndex]?.acf?.explore_image_tab1?.url)
            setExplore_image_tab2(props.data[foundIndex]?.acf?.explore_image_tab2?.url)
            setExplore_image_tab3(props.data[foundIndex]?.acf?.explore_image_tab3?.url)
            setExplore_button_url_tab2(props.data[foundIndex]?.acf?.explore_button_url_tab2?.url)
            setExplore_button_url_tab3(props.data[foundIndex]?.acf?.explore_button_url_tab3?.url)
            }
        }
      }, [props.data]);


  return (
    <div className='explore position-relative'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className='custom-container'>
                <Row>
                    <Col lg={3}>
                        <Nav variant="pills" className="flex-column explore-tab-names">
                            <Nav.Item>
                                <Nav.Link eventKey="first">{explore_tab1}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">{explore_tab2}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="three">{explore_tab3}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col lg={9}>
                        <div className='d-flex'>
                            <Tab.Content className='explore-tabs'>
                                <Tab.Pane eventKey="first" className='explore-tabs-main'>
                                    <div className='explore-tabs-text'>
                                        <div className='explore-tabs-content'>
                                            <div dangerouslySetInnerHTML={{__html: section_content_tab_1}}></div>
                                        </div>
                                        <Button type="button" className='round-radius-btn'>{explore_button_text_tab1}</Button>
                                    </div>
                                    <div className='explore-img'>
                                        <img src={explore_image_tab1} alt="explore" className='img-fluid'/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" className='explore-tabs-main'>
                                    <div className='explore-tabs-text'>
                                        <div className='explore-tabs-content'>
                                            <div dangerouslySetInnerHTML={{__html: section_content_tab_2}}></div>
                                        </div>
                                        <Link to={explore_button_url_tab2}><Button type="button" className='round-radius-btn'>{explore_button_text_tab2}</Button></Link>
                                    </div>
                                    <div className='explore-img explore-second-img'>
                                        <img src={explore_image_tab2} alt="explore" className='img-fluid'/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="three" className='explore-tabs-main'>
                                    <div className='explore-tabs-text'>
                                        <div className='explore-tabs-content'>
                                            <div dangerouslySetInnerHTML={{__html: section_content_tab_3}}></div>
                                        </div>
                                        <Link to={explore_button_url_tab3}><Button type="button" className='round-radius-btn'>{explore_button_text_tab3}</Button></Link>
                                    </div>
                                    <div className='explore-img'>
                                        <img src={explore_image_tab3} alt="explore" className='img-fluid'/>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                            
                        </div>
                    </Col>
                </Row>
            </div>
        </Tab.Container>
        
    </div>
  )
}

export default Explore