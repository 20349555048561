import React, {useEffect, useState} from 'react'
import { Col, Row, Accordion,Button} from 'react-bootstrap';
import { Link} from 'react-router-dom';
import Lottie from 'react-lottie-player'
import questionMark from '../../Assets/Lottie/question-mark.json'
import './Faqs.css';


function Faqs(props) {

    const [faqsdata, setFaqsdata] = useState([]);

    useEffect(() => {
        if(props.data && props.data.length > 0 ){
            let foundIndex = props.data.findIndex(item => item.slug == 'home')
            if(foundIndex > -1) {
            setFaqsdata(props.data[foundIndex]?.acf?.faqs)
            }
        }
      }, [props]);
    
  return (
    <div className='faqs-section'>
        <div className='custom-container'>
            <Row>
                <Col lg={5}>
                    <div className='faqs-intro'>
                        <h2>Frequently Asked <span>QUESTIONS</span></h2>
                        <div className='faqs-q-mark text-center'>
                            {/* <img src={faqQMark} alt="team" className='img-fluid'/> */}
                            <Lottie loop animationData={questionMark} play className='questionMark-lottie'/>
                        </div>
                    </div>
                </Col>
                <Col lg={7}>
                    <div className='faq-accordion'>
                        <Accordion defaultActiveKey="0">
                        {  
                            faqsdata?.length > 0 && faqsdata?.slice(0,8).map((faq, i) =>
                            <Accordion.Item eventKey={i}>
                                <Accordion.Header>{faq.heading}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{__html: faq.text}}></div>
                                </Accordion.Body>
                            </Accordion.Item>
                              )
                            }
                        </Accordion>
                    </div>
                </Col>
                <Link to="/faq" className="a_faq"><Button type="button" className='round-radius-btn mt-5'>View All</Button></Link>
            </Row>
        </div>
    </div>
  )
}

export default Faqs