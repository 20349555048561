import React, { Fragment, useState, useEffect }  from 'react'
import axios from 'axios';
import teamBannerBg from '../../Assets/Images/team-banner-bg.png'
import Series1 from '../Series1/Series1'
import InnerBanner from '../InnerBanner/InnerBanner'
function Series1Page() {
  const [data, setData] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
      axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
          .then(res => {
            let foundIndex = res.data.findIndex(item => item.slug == 'mint')
            if(foundIndex > -1) {
              setData(res.data)
            }
          })
          .catch(err => console.log(err))
  }, []);

  return (
    <>
       
		<InnerBanner heading="Series" description="Cyberpunk" innerbanner={teamBannerBg}/>
        <Series1 data={data} />
		
    </>
  )
}

export default Series1Page