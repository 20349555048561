import React, {Fragment, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import Coming from '../../Assets/Images/coming-soon.png'


function ComingSoon() {
  const location = useLocation();
  const [description, setDescription] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
    setDescription(location.state?.seriesItem?.description)
  }, []);
  return (
    <>
    <Fragment>
      <div className="coming-soon">
          <div class='c_descrption' dangerouslySetInnerHTML={{__html: description}}></div>
          <img src={Coming} alt="coming Soon" className="img-fluid"/>
      </div>
    </Fragment>
	
    </>
  )
}

export default ComingSoon