import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import teamBannerBg from '../../Assets/Images/team-banner-bg.png'
import Mint from '../Mint/Mint'
import InnerBanner from '../InnerBanner/InnerBanner'
import ComingSoon from '../ComingSoon/ComingSoon'
function MintPage() {
  const [data, setData] = useState('');
  const [banner_main_heading, setBanner_main_heading] = useState('');
	const [banner_sub_heading, setBanner_sub_heading] = useState('');
  const [banner_image, setBanner_image] = useState('');
  const [mint_page_show, setMint_page_show] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
      axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
          .then(res => {
            let foundIndex = res.data.findIndex(item => item.slug == 'mint')
            if(foundIndex > -1) {
              setData(res.data)
              setBanner_main_heading(res.data[foundIndex]?.acf?.banner_main_heading)
              setBanner_sub_heading(res.data[foundIndex]?.acf?.banner_sub_heading)
              setBanner_image(res.data[foundIndex]?.acf?.banner_image?.url)
              setMint_page_show(res.data[foundIndex]?.acf?.mint_page_show)
            }
          })
          .catch(err => console.log(err))
  }, []);
  return (
    <>
		{
    mint_page_show == true ? (
      <Fragment>
        <InnerBanner heading={banner_main_heading} description={banner_sub_heading} innerbanner={banner_image} data={data}/>
        <Mint data={data}/>
      </Fragment>
      ) : (
        <ComingSoon/> 
      )
      }
    </>
  )
}

export default MintPage