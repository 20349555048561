import React, {Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import Coming from '../../Assets/Images/coming-soon.png'

function Compete() {
  const [data, setData] = useState('');
  const [description, setDescription] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
        .then(res => {
          let foundIndex = res.data.findIndex(item => item.slug == 'compete')
          if(foundIndex > -1) {
            setData(res.data);
            setDescription(res.data[foundIndex]?.acf?.description);
          }
        })
        .catch(err => console.log(err))
}, []);
  return (
    <>
    <Fragment>
      <div className="coming-soon">
          <div class='c_descrption' dangerouslySetInnerHTML={{__html: description}}></div>
          <img src={Coming} alt="coming Soon" className="img-fluid"/>
      </div>
    </Fragment>
    </>
  )
}

export default Compete