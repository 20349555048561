import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios';
import Banner from '../Banner/Banner';
import Discover from '../Discover/Discover';
import Explore from '../Explore/Explore';
import Roadmap from '../Roadmap/Roadmap';
import Team from '../Team/Team';
import Faqs from '../Faqs/Faqs';

function Home() {

    const [data, setData] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
            .then(res => {
                setData(res.data)
            })
            .catch(err => console.log(err))
    }, []);


    return (
        <>
            <Banner  data={data} />
            <Discover data={data} />
            <Explore  data={data} />
            <Roadmap  data={data} />
            <Team  data={data} />
            <Faqs  data={data} />
        </>
    )
}
export default Home