import React from 'react'
import {useLocation } from 'react-router-dom'
import teamBannerBg from '../../Assets/Images/team-banner-bg.png'
import Series1 from '../Series1/Series1'
import InnerBanner from '../InnerBanner/InnerBanner'
function DetailSeries() {
    console.log("details page");
    const location = useLocation();
    console.log(location.state.seriesItem);
  return (
    <>
		<InnerBanner heading="Details" description="Cyberpunk" innerbanner={teamBannerBg}/>
        {/* <Series1/> */}
		
    </>
  )
}

export default DetailSeries