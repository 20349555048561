import React, { useEffect, useState } from 'react'
import {useLocation } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import '../Passes/Passes.css'
import Web3 from 'web3'
import Series2abi from '../../Abi/series2abi.json';
import MerkleMint from '../../Abi/merklemint.json';
var Contract = require('web3-eth-contract');






var web3 = new Web3(Web3.givenProvider || 'https://rinkeby.infura.io/v3/8f28c0181af64185b5e179576eb4301d');

function Mainmint() {
  const [mint_your_passes, setMint_your_passes] = useState('');
  const [series_name, setSeries_name] = useState('');
  const [data, setData] = useState({ address: null, balance: null });
  const location = useLocation();
  const [burnPass, setBurnPass] = useState(false);
  const [mintPass, setMintPass] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
       btnhandler();
       axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
            .then(res => {
                if(res.data && res.data.length > 0 ){
                  let foundIndex = res.data.findIndex(item => item.slug == 'passes')
                  if(foundIndex > -1) {
                    setMint_your_passes(res.data[foundIndex]?.acf?.mint_your_nfts)
                  }
              }
              if(res.data && res.data.length > 0 ){
                let foundIndex = res.data.findIndex(item => item.slug == 'mint')
                if(foundIndex > -1) {
                    setSeries_name(res.data[foundIndex]?.acf?.series[1]?.name)
                }
            }
            })
            .catch(err => console.log(err))
  }, []);

  const setMintPassHandler = () => {
    setBurnPass(false);
    setMintPass(true);
  }

  const backButtonHandler = () => {
    setBurnPass(false);
    setMintPass(false);
  }

  const [counter, setCounter] = useState(1);

  //increase counter
  const increase = () => {
    setCounter(count => count + 1);
  };

  //decrease counter
  const decrease = () => {
    if(counter == 1){
        setCounter(count => 1);
    }else{
        setCounter(count => count - 1);
    }
  };
  
  const minimum = () => {
    setCounter(count => 1);
  };
   
  const maximum = () => {
    setCounter(count => location.state.seriesItem.size);
  };
   
  const [isMinting, setIsMinting] = useState(false);

  const btnhandler = async () => {

    if (data.address) {
      setData({ address: null, balance: null })
    } else {
      // Asking if metamask is already present or not
      if (window.ethereum) {

        // res[0] for fetching a first wallet
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then(async (res) => {
            const chainId = await web3.eth.getChainId();
            const balance = await web3.eth.getBalance(res[0])


            if (window.ethereum.networkVersion !== process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) {
              try {
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) }]
                });
              } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                  await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                      {
                        chainName: 'Ethereum Mainnet',
                        chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID),
                        nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
                        rpcUrls: ['https://mainnet.infura.io/v3/']
                      }
                    ]
                  });
                }
              }
            }
            setData({ address: res[0], balance: balance });
            let contract = new web3.eth.Contract(MerkleMint, process.env.REACT_APP_MERKLE_MINT_CONTRACT_ADDRESS);
          });
      } else {
        alert("install metamask extension!!");
      }
    }
  };

  async function getAccount() {
    const accounts = await window.ethereum.enable();
    const account = accounts[0];
    const balance = await web3.eth.getBalance(account);

    if (window.ethereum.networkVersion !== process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) }]
        });
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Ethereum Mainnet',
                chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID),
                nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
                rpcUrls: ['https://mainnet.infura.io/v3/']
              }
            ]
          });
        }
      }
    }

    setData({ address: account, balance: balance });
  }

  window.ethereum.on('accountsChanged', function (accounts) {
    getAccount();
  })


  const handlemint = async () => {
    try {
      setIsMinting(true);
      console.log("window.ethereum.selectedAddress", window.ethereum.selectedAddress);
      let contract =  new web3.eth.Contract(Series2abi, process.env.REACT_APP_series2_CONTRACT_ADDRESS);
      console.log("contract", contract);
      const mintLeftover = await contract.methods.mintSale(1, ['0x0'] ).send({ from: window.ethereum.selectedAddress});
      if(mintLeftover){
        setIsMinting(false);
      }else{
        console.log("fail minting");
      }
    } catch (err) {
      setIsMinting(false)
      console.log(err)
    }
  }

  return (
    <section className='mint-detail-wrapper burning-box'>
      <Container>
        {!burnPass && !mintPass ?
          <div className='detail-box text-center pt-0'>
            <button className='text-uppercase btn-pass' onClick={setMintPassHandler}>mint your NFTs</button>
            <div className='mint_your_passes'>
              <div dangerouslySetInnerHTML={{__html: mint_your_passes}}></div>
            </div>
          </div> : null}
        {
          burnPass &&
          <div className='detail-box text-center position-relative'>
          </div>
        }
        {
          mintPass &&
          <div className='detail-box text-center position-relative mt-5 pt-5'>
            <p className='text-uppercase'>{series_name}</p>
            <h2 className='text-uppercase mb-3'>minitng</h2>
            <span className='minting-subtitle'>Enter the legend and generate one of the first ethnos.</span>
            <div className='btn-wrapper mt-5 d-flex flex-column align-items-center'>
              <Button className='btn mb-2 btn-1' onClick={() => btnhandler()} variant='primary'>
                {data.address ? data.address.replace(data.address.substring(4, 38), '.....') : 'CONNECT'}
              </Button>         
              {isMinting ?<button type='button' disabled className='btn btn-4' >minting ...</button> :<button type='button' className='btn btn-4' onClick={() => handlemint()}>mint</button>}
            </div>
          </div>

        }
        {burnPass || mintPass ?
          <div className='back-btn text-center'>
            <button type='button' onClick={backButtonHandler}><FontAwesomeIcon icon={faArrowLeft} /></button>
          </div> : null}
      </Container >
    </section >
  )
}

export default Mainmint