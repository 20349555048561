import React, {useEffect, useState} from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import './Roadmap.css'
function Roadmap(props) {

    const [roadmap_heading, setRoadmap_heading] = useState('');
    const [roadmap_subheading, setRoadmap_subheading] = useState('');
    const [pointsdata, setPointsdata] = useState([]);

      useEffect(() => {
        if(props.data && props.data.length > 0 ){

            let foundIndex = props.data.findIndex(item => item.slug == 'home')
            if(foundIndex > -1) {
                setRoadmap_heading(props.data[foundIndex]?.acf?.roadmap_heading)
                setRoadmap_subheading(props.data[foundIndex]?.acf?.roadmap_subheading)
                setPointsdata(props.data[foundIndex]?.acf?.roadmap_points)
            }
        }
      }, [props]);
  return (

    <div className='roadmap'>
        <div className='roadmap-header'>
            <h2>{roadmap_heading}</h2>
            <p>{roadmap_subheading}</p>
            <h3>How we’re doing</h3>
        </div>
        <div className='roadmap-body'>
            <Container>
            {  
                pointsdata.length > 0 && pointsdata.map((points, index) =>
                index % 2 ?
                <Row className='justify-content-end flex-column-reverse'>
                    <Col sm={6}  col={12} className="roadmap-content-left">
                        <div className='roadmap-content  p-4 text-end'>
                            <h4>{points.point_number}</h4>
                            <div>
                            <div dangerouslySetInnerHTML={{__html: points.point_text}}></div>
                            </div>
                        </div>
                    </Col>
                </Row>
                :
                <Row className='justify-content-end'>
                <Col sm={6} col={12} className={` ${index == 0 ? 'active' :'' } roadmap-content-right `}>
                    <div className='roadmap-content  p-4'>
                        <h4>{points.point_number}</h4>
                        <div>
                        <div dangerouslySetInnerHTML={{__html: points.point_text}}></div>
                        </div>
                    </div>
                </Col>
            </Row>
                )
            }
            
            </Container>
        </div>
    </div>
  )
}

export default Roadmap