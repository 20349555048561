import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Home from './Components/Pages/HomePage';
import FaqPage from './Components/Pages/FaqPage';
import TeamPage from './Components/Pages/TeamPage';
import RoadmapPage from './Components/Pages/RoadmapPage';
import LorePage from './Components/Pages/LorePage';
import Header from './Components/Header/Header'
import Footer from "./Components/Footer/Footer"
import JoinUS from './Components/JoinUS/JoinUS'
import Mint from './Components/Pages/MintPage';
import Series1 from './Components/Pages/Series1Page';
import DetailSeries from './Components/DetailSeries/DetailSeries';
import Passes from './Components/Passes/Passes';
import FeaturedArtist from './Components/Pages/FeaturedArtist';
import Governance from './Components/Pages/Governance';
import Compete from './Components/Pages/Compete';
import ComingSoon from './Components/ComingSoon/ComingSoon'
import Series2 from './Components/Series2/Series2'
import './App.css';
import Traits from './Components/Pages/Traits';
import Vault from './Components/Pages/Vault';
import Premint from './Components/Series2/Premint';
import Mainmint from './Components/Series2/Mainmint';


function App() {
	const [data, setData] = useState('');
    useEffect(() => {
        axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
            .then(res => {
                setData(res.data)
            })
            .catch(err => console.log(err))
    }, []);
	return (
		<BrowserRouter>
		<Header/>
			<Routes>	
				<Route exact path="/" element={<Home/>}/>
				<Route exact path="/faq" element={<FaqPage/>}/>
				<Route exact path="/team" element={<TeamPage/>}/>
				<Route exact path="/roadmap" element={<RoadmapPage/>}/>
				<Route exact path="/lore" element={<LorePage/>}/>
				<Route exact path="/traits" element={<Traits/>}/>	
				<Route exact path="/mint" element={<Mint/>}/>
				<Route exact path="/vault" element={<Vault/>}/>				
				<Route exact path="/series" element={<Series1/>}/>
				<Route exact path="/detailseries" element={<DetailSeries/>}/>
				<Route exact path="/passes" element={<Passes/>}/>
				<Route exact path="/featuredartist" element={<FeaturedArtist/>}/>	
				<Route exact path="/governance" element={<Governance/>}/>
				<Route exact path="/compete" element={<Compete/>}/>
				<Route exact path="/comingsoon" element={<ComingSoon/>}/>
				<Route exact path="/series2" element={<Series2/>}/>
				<Route exact path="/premint" element={<Premint/>}/>
				<Route exact path="/mainmint" element={<Mainmint/>}/>									
			</Routes>
			<JoinUS data={data}/>
			<Footer/>
		</BrowserRouter>
	);
}

export default App;
