import React, { useState, useEffect, Fragment, Component } from "react";
import './Timer.css';
import { Link } from 'react-router-dom'
import Coming from '../../Assets/Images/coming-soon.png'
import Web3 from 'web3'
import Series2abi from '../../Abi/series2abi.json';
var Contract = require('web3-eth-contract');

var web3 = new Web3(Web3.givenProvider || 'https://rinkeby.infura.io/v3/8f28c0181af64185b5e179576eb4301d');
class Timer extends Component {
  
  constructor(props) {
    super(props);
    this.countDownId = null;
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false,
      timestamp: 0,
    };
  }
  componentDidMount() {
    this.countDownId = setInterval(this.timerInit, 1000);
  }

  componentWillUnmount() {
    if (this.countDownId) {
      clearInterval(this.countDownId);
    }
  }

 
  
  timerInit = () => {
    const { startDate } = this.props;
    // console.log(startDate);
    const now = new Date().getTime();
    if (!startDate) {
      this.setState({ expired: true });
      return;
    }
    const countDownStartDate = new Date(startDate).getTime();
    const distance = countDownStartDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // For countdown is finished
    if (distance < 0) {
      clearInterval(this.countDownId);
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: true
      });
      return;
    }
    this.setState({ days, hours, minutes, seconds, expired: false });
  };

  render() {
    const { days, hours, minutes, seconds, expired } = this.state;
        
    if (expired) {
      return (
      <Fragment>
      <div className="btn-actions">
      <Link to="/premint"><button className="round-radius-btn yellow">Mint Now</button></Link>
      </div>
      </Fragment>
      );
    }
    return (
        <Fragment>
      <div className="timer">
        <div>
          {days}
          <span>d</span>
        </div>
        <div>
          {hours}
          <span>h</span>
        </div>
        <div>
          {minutes}
          <span>m</span>
        </div>
        <div>
          {seconds}
          <span>s</span>
        </div>
      </div>
      </Fragment>
    );
  }
}
export default Timer;