import React, { Fragment, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Pagination, ProgressBar, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import './Passes.css'
import Web3 from 'web3'
import Contractabi from '../../contractabi.json';
import MerkleMint from '../../Abi/merklemint.json';
import mintpass from '../../Abi/mintpass.json';
import seriesone from '../../Abi/seriesone.json';
var Contract = require('web3-eth-contract');






var web3 = new Web3(Web3.givenProvider || 'https://rinkeby.infura.io/v3/8f28c0181af64185b5e179576eb4301d');

function Passes() {
  const [mint_your_passes, setMint_your_passes] = useState('');
  const [burn_your_passes, setBurn_your_passes] = useState('');
  const [data, setData] = useState({ address: null, balance: null });
  const location = useLocation();
  const [burnPass, setBurnPass] = useState(false);
  const [mintPass, setMintPass] = useState(false);
  const [tokenid, setTokenid] = useState(null);
  const [tokenlenghth, setTokenlenghth] = useState(null);
  const [approvestate, setApprovestate] = useState(false);
  const [burnapprovestate, setBurnapprovestate] = useState(false);
  const [getapprove, setGetapprove] = useState(false);
  const [lessbalance, setLessbalance] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
       btnhandler();
       isapproved();
       axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
            .then(res => {
                if(res.data && res.data.length > 0 ){
                  let foundIndex = res.data.findIndex(item => item.slug == 'passes')
                  console.log(res.data[foundIndex]?.acf?.mint_your_passes);
                  if(foundIndex > -1) {
                    setMint_your_passes(res.data[foundIndex]?.acf?.mint_your_passes)
                    setBurn_your_passes(res.data[foundIndex]?.acf?.burn_your_passes)
                  }
              }
            })
            .catch(err => console.log(err))
  }, []);
  const setBurnPassHandler = () => {
    isapproved();
    console.log("data.address",data.address)
    setBurnPass(true);
    setMintPass(false);
  }

  const setMintPassHandler = () => {
    setBurnPass(false);
    setMintPass(true);
  }

  const backButtonHandler = () => {
    setBurnPass(false);
    setMintPass(false);
  }

  const [counter, setCounter] = useState(1);
  const [counter1, setCounter1] = useState(0);  

  //increase counter
  const increase = () => {
    if(counter == location.state.seriesItem.size + 1){
        setCounter(count => location.state.seriesItem.size);
    }else{
        setCounter(count => count + 1);
    }
  };

  //decrease counter
  const decrease = () => {
    if(counter == 1){
        setCounter(count => 1);
    }else{
        setCounter(count => count - 1);
    }
  };
  
  const minimum = () => {
    setCounter(count => 1);
  };
   
  const maximum = () => {
    setCounter(count => location.state.seriesItem.size);
  };
   

  //reset counter 
  const reset = () => {
    setCounter(0)
  }

  // let total_price = counter * location.state.seriesItem.price;
  // let total_price_str = web3.utils.toWei(total_price.toString());
  // let bal1 = data.balance;
  // if(Number(bal1) >= Number(total_price_str)){
  //   setLessbalance(" ");
  // }else{
  //   setLessbalance("You don't have enough ethers to mint these passes");
  // }
  const [isMinting, setIsMinting] = useState(false);
  const [isBurning, setIsBurning] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const percentage = counter/location.state.seriesItem.size*100;
  const now = percentage.toFixed(1);
  const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

  const handlemint = async () => {

    try {
      let total_price = counter * location.state.seriesItem.price;
      let total_price_str = web3.utils.toWei(total_price.toString());
      let contract = new web3.eth.Contract(MerkleMint, process.env.REACT_APP_MERKLE_MINT_CONTRACT_ADDRESS);
      let bal1 = data.balance;
      if(Number(bal1) >= Number(total_price_str)){
        setLessbalance(" ");
        console.log("minting");
        setIsMinting(true);
        const mintLeftover = await contract.methods.mintLeftover(counter).send({ from: data.address,value: web3.utils.toWei(total_price.toString()) });
        console.log("mintLeftover", mintLeftover);
        if(mintLeftover){
          console.log("minting");
          setIsMinting(false);
        }else{
          console.log("fail minting");
        }
      }else{
        setLessbalance("You don't have enough ethers to mint these passes or check your wallet connection.");
      }
    } catch (err) {
      setIsMinting(false)
      console.log(err)
    }
  }
  const isapproved = async () => {
    try {
      console.log("data.address", data.address);
      let contract = new web3.eth.Contract(mintpass, process.env.REACT_APP_mintpass_MINT_CONTRACT_ADDRESS);
     
      const approve = await contract.methods.isApprovedForAll(data.address, process.env.REACT_APP_mintpass_MINT_CONTRACT_ADDRESS).call();
     
      console.log("check approved", approve);
      if(approve == true){
        setApprovestate(true);
        setBurnapprovestate(false);
      }else{
        setApprovestate(false);
        setBurnapprovestate(true);
      }
      const balanceOf = await contract.methods.balanceOf(data.address, 1).call();
      console.log("balanceOfdsd", balanceOf);
      setCounter1(balanceOf);
  
    } catch (err) {
      console.log(err)
    }
  }
  const handleburn = async () => {
    try {
      console.log("counter1", counter1);
      console.log("process.env.REACT_APP_seriesone_MINT_CONTRACT_ADDRESS", process.env.REACT_APP_seriesone_MINT_CONTRACT_ADDRESS);
      console.log(data.address)
      setIsBurning(true);
      setBurnapprovestate(true);
      let contract = new web3.eth.Contract(seriesone, process.env.REACT_APP_seriesone_MINT_CONTRACT_ADDRESS);
      const burn = await contract.methods.mint(counter1).send({ from: data.address });
      if(burn){
        setIsBurning(false);
        setBurnapprovestate(false);
        console.log("burn");
      }

    } catch (err) {
      setIsBurning(false);
      setBurnapprovestate(false);
      console.log(err)
    }
  }
  const handlapprove = async () => {
    try {
      setIsApprove(true);
      let contract = new web3.eth.Contract(mintpass, process.env.REACT_APP_mintpass_MINT_CONTRACT_ADDRESS);
      const approve = await contract.methods.setApprovalForAll(process.env.REACT_APP_mintpass_MINT_CONTRACT_ADDRESS, true).send({ from: data.address });
      console.log("check approve", approve);
      if(approve == true){
        setIsApprove(false);
        setApprovestate(true);
        setBurnapprovestate(false);
      }else{
        setApprovestate(false);
        setBurnapprovestate(true);  
      }

    } catch (err) {
      setIsApprove(false);
      console.log(err)
    }
  }
  const btnhandler = async () => {

    if (data.address) {
      setData({ address: null, balance: null })
    } else {
      // Asking if metamask is already present or not
      if (window.ethereum) {

        // res[0] for fetching a first wallet
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then(async (res) => {
            const chainId = await web3.eth.getChainId();
            const balance = await web3.eth.getBalance(res[0])


            if (window.ethereum.networkVersion !== 4) {
              try {
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) }]
                });
              } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                  await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                      {
                        chainName: 'Ethereum Mainnet',
                        chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID),
                        nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
                        rpcUrls: ['https://mainnet.infura.io/v3/']
                      }
                    ]
                  });
                }
              }
            }
            setData({ address: res[0], balance: balance });
            let contract = new web3.eth.Contract(MerkleMint, process.env.REACT_APP_MERKLE_MINT_CONTRACT_ADDRESS);
          });
      } else {
        alert("install metamask extension!!");
      }
    }
  };
  const increase1 = async() => {
    console.log(tokenid[counter1 + 1]);
    if(counter1 == tokenlenghth - 1){
        setCounter1(count => tokenlenghth - 1);
    }else{
        setCounter1(count => count + 1);
    }
  };

  //decrease counter
  const decrease1 = async() => {
    if(counter1 == 0){
        setCounter1(count => 0);
    }else{
        setCounter1(count => count - 1);
    }
    
  };
  
  const minimum1 = async() => {
    setCounter1(count => 0);
  };
  const maximum1 = async() => {
    // setCounter1(count => tokenlenghth - 1);
  };
  async function getAccount() {
    const accounts = await window.ethereum.enable();
    const account = accounts[0];
    const balance = await web3.eth.getBalance(account);
    const chainId = await web3.eth.getChainId();

    if (window.ethereum.networkVersion !== 4) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID) }]
        });
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Ethereum Mainnet',
                chainId: web3.utils.toHex(process.env.REACT_APP_DEFAULT_NETWORK_CHAINID),
                nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
                rpcUrls: ['https://mainnet.infura.io/v3/']
              }
            ]
          });
        }
      }
    }

    setData({ address: account, balance: balance });
  }

  window.ethereum.on('accountsChanged', function (accounts) {
    getAccount();
  })




  return (
    <section className='mint-detail-wrapper burning-box'>
      <Container>
        {!burnPass && !mintPass ?
          <div className='detail-box text-center pt-0'>
            <button className='text-uppercase btn-pass' onClick={setMintPassHandler}>mint your passes</button>
            <div className='mint_your_passes'>
              <div dangerouslySetInnerHTML={{__html: mint_your_passes}}></div>
            </div>
            <button className='text-uppercase btn-pass' onClick={setBurnPassHandler}>burn your passes</button>
            <div className='mint_your_passes'>
              <div dangerouslySetInnerHTML={{__html: burn_your_passes}}></div>
            </div>
          </div> : null}
        {
          burnPass &&
          <div className='detail-box text-center position-relative'>
            <h2 className='text-uppercase'>burning</h2>
            <div className='btn-wrapper d-flex flex-column align-items-center'>
              <Button className='btn mb-2 btn-1' onClick={() => btnhandler()} variant='primary'>
                {data.address ? data.address.replace(data.address.substring(4, 38), '.....') : 'CONNECT'}
              </Button>
              <div className="counter mb-2">
                  <div className="btn__container">
                    <button className="control__btn"  onClick={minimum1}><FontAwesomeIcon icon={faAnglesLeft} /></button>
                    <button className="control__btn" onClick={decrease1}><FontAwesomeIcon icon={faChevronLeft} /></button>
                    {/* <span className="counter__output1">{tokenid && tokenid[counter1]}</span> */}
                    <span className="counter__output1">{counter1}</span> 
                    <button className="control__btn" onClick={increase1}><FontAwesomeIcon icon={faChevronRight} /></button>
                    <button className="control__btn" onClick={maximum1}><FontAwesomeIcon icon={faAnglesRight} /></button>
                    {/* <button className="reset" onClick={reset}>Reset</button> */}
                  </div>
              </div>             
              {isApprove ?<button type='button' className='btn mb-2' disabled={approvestate}>approved</button> :<button type='button' className='btn mb-2' disabled={approvestate} onClick={() => handlapprove()}>approve</button>}
              {isBurning ?<button type='button' className='btn btn-4' disabled={burnapprovestate}>burning...</button> :<button type='button' className='btn btn-4' disabled={burnapprovestate} onClick={() => handleburn()}>burn</button>}
            </div>
          </div>
        }
        {
          mintPass &&
          <div className='detail-box text-center position-relative mt-5 pt-5'>
            <p className='text-uppercase'>{location.state.seriesItem.name}</p>
            <h2 className='text-uppercase mb-3'>minitng</h2>
            <span className='minting-subtitle'>Enter the legend and generate one of the first {location.state.seriesItem.size} ethnos.</span>
            <div className='btn-wrapper mt-5 d-flex flex-column align-items-center'>
              <Button className='btn mb-2 btn-1' onClick={() => btnhandler()} variant='primary'>
                {data.address ? data.address.replace(data.address.substring(4, 38), '.....') : 'CONNECT'}
              </Button>
              <div className="counter mb-2">
                  <div className="btn__container">
                    <button className="control__btn" onClick={minimum}><FontAwesomeIcon icon={faAnglesLeft} /></button>
                    <button className="control__btn" onClick={decrease}><FontAwesomeIcon icon={faChevronLeft} /></button>
                    <span className="counter__output">{counter}</span>
                    <button className="control__btn" onClick={increase}><FontAwesomeIcon icon={faChevronRight} /></button>
                    <button className="control__btn" onClick={maximum}><FontAwesomeIcon icon={faAnglesRight} /></button>
                    {/* <button className="reset" onClick={reset}>Reset</button> */}
                  </div>
              </div>
              
              {isMinting ?<button type='button' disabled className='btn btn-4' >minting ...</button> :<button type='button' className='btn btn-4' onClick={() => handlemint()}>mint</button>}
              <div><span className='mint-bottom-text lessbalace'>{lessbalance}</span></div>
              <span className='mint-bottom-text'>{location.state.seriesItem.price} Eth + tx</span>
              <span className='mint-bottom-text'>Total Price: {counter * location.state.seriesItem.price} Eth + tx</span>
              <div className='progress-wrapper'>
                {progressInstance}
              </div>
            </div>
          </div>

        }
        {burnPass || mintPass ?
          <div className='back-btn text-center'>
            <button type='button' onClick={backButtonHandler}><FontAwesomeIcon icon={faArrowLeft} /></button>
          </div> : null}
      </Container >
    </section >
  )
}

export default Passes