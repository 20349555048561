import React, {useEffect, useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import teamOuterImg from '../../Assets/Images/team-outer-img.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Lottie from 'react-lottie-player'
import lottieTwitter from '../../Assets/Lottie/lottie-twitter.json'
import lottieDiscord from '../../Assets/Lottie/lottie-discord.json'

import './Team.css'

function Team(props) {

    const [teamdata, setTeamdata] = useState([]);

    useEffect(() => {
        if(props.data && props.data.length > 0 ){
            let foundIndex = props.data.findIndex(item => item.slug == 'home')
            if(foundIndex > -1) {
            setTeamdata(props.data[foundIndex]?.acf?.team_slider)
            }
        }
      }, [props]);

    var bannerSlider = {
		dots: false,
		infinite: true,
        // centerMode: false,
        adaptiveHeight: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
        centerMode: false,
        centerPadding: '18%',
        arrows: true,
		// autoplay:true,
		autoplaySpeed: 1000,
        initialSlide: 0,

		responsive: [
			{
			  breakpoint: 991,
			  settings: {
				slidesToShow: 3,				
			  }
			},
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,				
                }
              },
			{
			  breakpoint: 575,
			  settings: {
				slidesToShow: 1,				
			  }
			},
		]
	  };
  return (
    <div className='team'>
        <div className='custom-container'>
            <Row>
                <div className='team-header'>
                    <h2>WHO WE ARE?</h2>
                    <p>Meet the Ethnology Team</p>
                </div>
            </Row>
            <Row>
                <Col sm={9}>
                    <Slider  {...bannerSlider} className='team-row position-relative'>
                    {  
                        teamdata.length > 0 && teamdata.map((team) =>
                        <a target="_blank" href={team.twitter}>
                        <div className='team-single-box position-relative  '>
                            <div className='team-img-wrap'>
                                <img src={team.image.url} alt="team" className='img-fluid'/>
                            </div>
                            <div className='team-single-box-text'>
                                <strong className='d-block'>{team.name}</strong>
                                <span>{team.designation}</span>
                            </div>
                            <div className='team-single-box-icons d-inline-block'>
                                <div  className='d-flex align-items-center'>
                                    <a to={team.twitter} className='team-single-box-icons-discord  me-2 p-2 bd-dark'>
                                        <Lottie loop animationData={lottieTwitter} play className='twitter-lottie'/>
                                    </a>
                                    <a to={team.dicote} className='team-single-box-icons-discord'>
                                        <Lottie loop animationData={lottieDiscord} play className='dicord-lottie'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        </a>
                          )
                        }
                    </Slider>
                </Col>
                <Col sm={3}>
                    <div className='team-outer-img'>
                        <div className='team-outer-img-bg'>
                            <img src={teamOuterImg} alt="team" className='img-fluid'/>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Team