import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InnerBanner.css'
function InnerBanner(props) {
	const heading = props.heading;
	const description = props.description;
	return (
	<div className='main-banner inner-banner position-relative' style={{ backgroundImage: `url(${props.innerbanner})`}}>
		<div className='custom-container'>
			<div className='text-center'>
				<h1>{heading}</h1>
				<h3>{description}</h3>
			</div>
		</div>
	</div>
	)
}

export default InnerBanner