import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Button,a } from 'react-bootstrap';
import OurCoumminuty from "../../Assets/Images/our-community.png" 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord} from '@fortawesome/free-brands-svg-icons';

import './JoinUS.css'

function JoinUS(props) {

  const [community_heading, setCommunity_heading] = useState('');
  const [community_image, setCommunity_image] = useState('');
  const [comunity_twitter_url, setComunity_twitter_url] = useState('');
  const [community_discord_url, setCommunity_discord_url] = useState('');

  useEffect(() => {
    if(props.data && props.data.length > 0 ){
      let foundIndex = props.data.findIndex(item => item.slug == 'home')
      if(foundIndex > -1) {
      setCommunity_heading(props.data[foundIndex]?.acf?.community_heading)
      setCommunity_image(props.data[foundIndex]?.acf?.community_image?.url)
      setComunity_twitter_url(props.data[foundIndex]?.acf?.comunity_twitter_url?.url)
      setCommunity_discord_url(props.data[foundIndex]?.acf?.community_discord_url?.url)
      }
    }
  }, [props]);
  
  return (
    <div className='join-us'>
        <div className='join-us-header mb-4'>
            <h2>{community_heading}</h2>
            <div className='d-flex justify-content-center'>
              <a target="_blank" href={comunity_twitter_url} >
                <Button type="button" className='twitter-btn '>
                    <FontAwesomeIcon icon={faTwitter} className="me-2"/>
                    <span>Twitter</span>
                </Button>
                </a>
                <a target="_blank" href={community_discord_url} >
                <Button type="button" className='discord-btn ms-3'>
                  <FontAwesomeIcon icon={faDiscord} className="me-2"/>
                  <span>Discord</span>
                </Button>
                </a>
            </div>
        </div>
        <div className="joinus-img-wrapper">
            <img src={community_image} alt="community" className='img-fluid' />
        </div>
    </div>
  )
}

export default JoinUS