import React, {Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import Coming from '../../Assets/Images/coming-soon.png'
import Timer from "../Timer/Timer";
import Timer2 from "../Timer/Timer2";
import Web3 from 'web3'
import Series2abi from '../../Abi/series2abi.json';
var Contract = require('web3-eth-contract');

var web3 = new Web3(Web3.givenProvider || 'https://rinkeby.infura.io/v3/8f28c0181af64185b5e179576eb4301d');

function Series2() {

    const [description, setDescription] = useState('');
    const [starttimestamp, setStarttimestamp] = useState('');
    const [startmaintimestamp, setStartmaintimestamp] = useState('');
    useEffect(() => {
      window.scrollTo(0, 0);
      handle_premint_time();
        // clearTimer(getDeadTime());
        axios.get('https://ethnology.server18.arhamsoft.info/ethno_wordpress/wp-json/wp/v2/ethnology')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    let foundIndex = res.data.findIndex(item => item.slug == 'mint')
                    if (foundIndex > -1) {
                        console.log(res.data[foundIndex]?.acf?.series[1]?.description);
                        setDescription(res.data[foundIndex]?.acf?.series[1]?.description)
                    }
                  }
            })
            .catch(err => console.log(err))
    }, []);

    const handle_premint_time = async () => {
      try {
        let contract = new web3.eth.Contract(Series2abi, process.env.REACT_APP_series2_CONTRACT_ADDRESS);
        const timestamp = await contract.methods.preMintStart().call();
        const timestamp2 = await contract.methods.preMintEnd().call();
        setStarttimestamp(timestamp);
        setStartmaintimestamp(timestamp2);
      } catch (err) {
        console.log(err)
      }
    }
  const nowdate = new Date().getTime();
  console.log("nowdate", nowdate)
  console.log("starttimestamp", starttimestamp * 1000)
  console.log("startmaintimestamp", startmaintimestamp * 1000)
  let currentTimestamp = starttimestamp;
  var converted_date = new Date(currentTimestamp * 1000);
  const startDate = new Date(converted_date).getTime();
  // const startDate = new Date().getTime() + 5000;

  let currentTimestamp2 = startmaintimestamp;
  var converted_date2 = new Date(currentTimestamp2 * 1000);
  const startDate2 = new Date(converted_date2).getTime();

  return (
    <>
    <Fragment>
      <div className="coming-soon">
      { nowdate >= currentTimestamp *1000 && nowdate <= currentTimestamp2 * 1000 ?
      <div className="paid_mint"><h1 class='series2_heading'>Series 2 <span class='series2_free_mint'>(Main Sale)</span>
      <Timer2 startDate={startDate2} /></h1></div>
      : '' }
          <div class='c_descrption' dangerouslySetInnerHTML={{__html: description}}></div>
          {/* <img src={Coming} alt="coming Soon" className="img-fluid coming_soom_immg"/> */}
         {nowdate <= currentTimestamp2 ? <><h1 class='series2_heading'>Series 2 <span class='series2_free_mint'>(Pre Mint)</span></h1>
          <Timer startDate={startDate} /> </>: <div className="paid_mintss"><h1 class='series2_heading'>Series 2 <span class='series2_free_mint'>(Main Sale)</span>
      <Timer2 startDate={startDate2} /></h1></div>} 
      </div>
    </Fragment>
	
    </>
  )
}

export default Series2