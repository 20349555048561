import React, { useState, useEffect } from 'react';
import {Accordion} from 'react-bootstrap';
// import faqQMark from '../../Assets/Images/faq-q-mark.png';
// import Lottie from 'react-lottie-player'
// import questionMark from '../../Assets/Lottie/question-mark.json'
import '../Faqs/Faqs.css';
import './FaqsMain.css';
function FaqsMain(props) {

    const [faqsmaindata, setFaqsmaindata] = useState([]);

    useEffect(() => {
        if(props.data && props.data.length > 0 ){
            let foundIndex = props.data.findIndex(item => item.slug == 'home')
            if(foundIndex > -1) {
            setFaqsmaindata(props.data[foundIndex]?.acf?.faqs)
            }
        }
      }, [props]);
  return (
    <div className='faqs-main'>
        <div className='custom-container'>
            <div className='faq-accordion'>
            <Accordion defaultActiveKey="0">
            {  
                faqsmaindata.length > 0 && faqsmaindata.map((faqmain, i) =>
                    <Accordion.Item eventKey={i}>
                        <Accordion.Header>{faqmain.heading}</Accordion.Header>
                        <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: faqmain.text}}></div>
                        </Accordion.Body>
                    </Accordion.Item>
                    )
                }
                </Accordion>
            </div>
        </div>
    </div>
  )
}

export default FaqsMain